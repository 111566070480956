import type { TileState, TileValue } from "@/types"
import * as React from "react"

import Tile from "./tile"

interface Props {
  values?: TileValue[]
  state?: TileState[]
  row?: number
  currentRow?: number
  currentCol?: number
  onTileClick?: (row: number, col: number) => void
  tileSize?: number
  gap?: number
  animation?: string
  bounce?: boolean
}

function Row(props: Props) {
  const {
    values,
    state,
    row,
    currentRow,
    currentCol,
    tileSize,
    animation,
    bounce,
  } = props

  let cols = 5

  if (values) {
    cols = values.length
  }

  const tiles = []
  for (let col = 0; col < cols; col++) {
    tiles.push(
      <Tile
        key={`${row}-${col}`}
        row={row}
        col={col}
        value={values ? values[col] : undefined}
        state={state ? state[col] : undefined}
        width={tileSize}
        height={tileSize}
        isCurrent={currentRow == row && currentCol == col}
        onClick={currentRow == row ? props.onTileClick : undefined}
        bounce={currentRow !== undefined && currentRow - 1 == row && bounce}
      />
    )
  }

  return (
    <div
      key={row}
      className={`row grid ${
        currentRow == row && animation == "shake" ? animation : ""
      }`}
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gap: `${props.gap || 5}px`,
      }}
    >
      {tiles}
    </div>
  )
}

export default React.memo(Row)
